import React, {Fragment, useEffect, useState} from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout} from "../actions/commonActions";
import Axios from "axios";
import axios from "axios";
import Doctor from "./Doctor";

const Doctors = ({...rest}) => {
    let [items, setItems] = useState({
        lists: [],
        page:1,
        totalPage: 1,
        totalCount: 0,
    });

    let [status, setStatus] = useState({
        "allDoctorCnt": 0,
        "passDoctorCount": 0,
        "waitDoctorCount": 0,
        "rejectDoctorCount": 0,
        "stopDoctorCount": 0
    });

    useEffect(() => {
        getItems();
    }, []);

    const getItems = (params = {size:5, page: 1}, loadMore = false) => {
        axios.get(window.domain + "/api/admin/doctorList", {
            params: params
        }).then(response => {
            if(loadMore)
                return setItems({
                    ...items,
                    page: params.page,
                    lists: [...items.lists, ...response.data.lists]
                });

            setItems(response.data);

            setStatus(response.data.doctorStatus);
        });
    };

    const loadMore = () => {
        if(items.page < items.totalPage){
            getItems({size:10, page: items.page + 1}, true);
        }
    }

    return (
        <div className="sections">
            <div className="section-wrap">
                <div className="section">
                    <div className="m-title type02">의사</div>

                    <div className="mt-20"></div>

                    <div className="m-table type01">
                        <div className="thead">
                            <div className="tr">
                                <div className="th">병원명</div>
                                <div className="th">의사명</div>
                                <div className="th">면허번호</div>
                                <div className="th">수정일</div>
                                <div className="th">관리</div>
                            </div>
                        </div>
                        <div className="tbody">
                            {items.lists.map(item => <Doctor items={items} item={item} setItems={setItems} key={item.dc_idx} />)}
                        </div>
                    </div>

                    {
                        items.page < items.totalPage ?
                            <Fragment>
                                <div className="mt-40"></div>

                                <div className="m-btns">
                                    <button className="m-btn type01 type-middle bg-revert-primary" onClick={loadMore}>
                                        더보기
                                    </button>
                                </div>
                            </Fragment> : ""
                    }

                </div>
            </div>

            <div className="section-wrap section-wrap-count">
                <div className="section">
                    <div className="boxes">
                        <div className="box-wrap">
                            <div className="box">
                                <h3 className="title">총 등록 의사</h3>

                                <h3 className="content">
                                    <span className="point">{status.allDoctorCnt}</span>
                                    <span className="sub">개소</span>
                                </h3>
                            </div>
                        </div>
                        <div className="box-wrap">
                            <div className="box">
                                <h3 className="title">총 승인 의사</h3>

                                <h3 className="content">
                                    <span className="point">{status.passDoctorCount}</span>
                                    <span className="sub">개소</span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="boxes">
                        <div className="box-wrap">
                            <div className="box">
                                <h3 className="title">총 대기 의사</h3>

                                <h3 className="content">
                                    <span className="point">{status.waitDoctorCount}</span>
                                    <span className="sub">개소</span>
                                </h3>
                            </div>
                        </div>
                        <div className="box-wrap">
                            <div className="box">
                                <h3 className="title">총 반려 의사</h3>

                                <h3 className="content">
                                    <span className="point">{status.rejectDoctorCount}</span>
                                    <span className="sub">개소</span>
                                </h3>
                            </div>
                        </div>
                        <div className="box-wrap">
                            <div className="box">
                                <h3 className="title">총 중지 의사</h3>

                                <h3 className="content">
                                    <span className="point">{status.stopDoctorCount}</span>
                                    <span className="sub">개소</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapState = (state) => {
    return {
        user: state.common.user,
        token: state.common.token
    }
};

const mapDispatch = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        }
    }
};

export default connect(mapState, mapDispatch)(Doctors);
