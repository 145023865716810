import React from 'react';
import {Provider} from 'react-redux';
import store from './store';
import Login from './pages/Login';
import Main from './pages/Main';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import UserInfo from './보관/UserInfo';
import Header from "./components/Header";
import NoticesIndex from "./pages/NoticesIndex";
import Pop from "./components/Pop";
import AuthRoute from "./components/AuthRoute";
import Footer from "./components/Footer";

window.store = store;

const App = () => {

	return (
		<Provider store={store}>

			<BrowserRouter>
				<Pop />

				<Header />

				<main className="main">
					<Switch>
						<AuthRoute exact path={"/"} component={Main} />
						<AuthRoute exact path={"/notices"} component={NoticesIndex} />
						<Route exact path={"/login"} component={Login} />
					</Switch>
				</main>

				<Footer />
			</BrowserRouter>

		</Provider>
	);
};

export default App;
