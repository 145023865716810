import React, {Fragment, useEffect, useState} from 'react';
import {setPop} from "../actions/commonActions";
import {connect} from 'react-redux';
import Parser from "html-react-parser";

const Pop = ({ pop = {}, setPop}) => {
    useEffect(() => {

    }, [pop]);

    if(pop) {
        if (pop.img_url)
            return (<div className={"m-pop type01 type-img"}>
                <div className="m-pop-inner">
                    <button type={"button"} className="btn-close" onClick={()=>setPop(null)}>
                        <img src="/img/x.png" alt=""/>
                    </button>

                    <img src={pop.img_url} alt="" className={"img"}/>
                </div>
            </div>);

        return (<div className={"m-pop type01"}>
            <div className="m-pop-inner">

                {pop.needLogo ? <img src="/img/logo-bing.png" alt="" className="logo"/> : null}

                {pop.title ? <h3 className="m-pop-title">{pop.title}</h3> : null}

                <div className="section">{pop.description}</div>

                {pop.buttons ?
                    <div className="btns">
                        {pop.buttons.map((button, index) =>(
                            <Fragment key={index}>
                                {button}
                            </Fragment>
                        ))}
                    </div>
                    : null
                }

            </div>
        </div>)
    }

    return null;
};

const mapState = (state) => {
    return {
        pop: state.common.pop
    }
};

const mapDispatch = (dispatch) => {
    return {
        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(mapState, mapDispatch)(Pop);
