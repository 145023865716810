import React, {Fragment, useEffect, useState} from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout,setPop} from "../actions/commonActions";
import Axios from "axios";

const Doctor = ({item, items, setItems, setPop, ...rest}) => {
    const [active, setActive] = useState(false);

    useEffect(() => {

    }, []);

    const changeStatus = (status) => {
        Axios.patch(window.domain + "/api/admin/doctor/" + item.dc_idx, {
            status: status
        }).then(response => {
            setItems({
                ...items,
                lists: items.lists.map((itemData) => {
                    if(itemData.dc_idx == item.dc_idx)
                        return {
                            ...item,
                            is_passed: status
                        }

                    return itemData;
                })
            })
        })
    }

    return (
        <Fragment key={item.dc_idx}>
            <div className="tr" onClick={() => setActive(!active)}>
                <div className="td">{item.hospital_name}</div>
                <div className="td">{item.dc_name}</div>
                <div className="td">{item.license_number}</div>
                <div className="td">{item.up_date}</div>
                <div className="td">
                    <span className={"m-btn type02 " + item.is_passed}>{item.is_passed}</span>
                </div>
            </div>

            {
                active ?
                    <div className="box-more">
                        <div className="fragments">
                            <div className="fragment-wrap">
                                <div className="fragment box-info">
                                    <h3 className="title">{item.dc_name}</h3>

                                    <div className="boxes">
                                        <div className="box-wrap">
                                            <div className="box">
                                                <div className="info">
                                                    <h3 className="info-title">이메일</h3>
                                                    <p className="info-body">{item.id}</p>
                                                </div>

                                                <div className="info">
                                                    <h3 className="info-title">전화번호</h3>
                                                    <p className="info-body">{item.dc_phone}</p>
                                                </div>

                                                <div className="info">
                                                    <h3 className="info-title">전문분야</h3>
                                                    <p className="info-body">{item.department}</p>
                                                </div>

                                                <div className="info">
                                                    <h3 className="info-title">진료과목</h3>
                                                    <p className="info-body">{item.symptom}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="box-wrap">
                                            <div className="box">
                                                <div className="info">
                                                    <h3 className="info-title">사업자등록번호</h3>
                                                    <p className="info-body">{item.business_number}</p>
                                                </div>
                                                <div className="info">
                                                    <h3 className="info-title">주소</h3>
                                                    <p className="info-body">{item.address} {item.address_detail}</p>
                                                </div>
                                                <div className="info">
                                                    <h3 className="info-title">연락처</h3>
                                                    <p className="info-body">{item.contact_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="fragment-wrap">
                                <div className="fragment box-imgs">
                                    <img src={item.license_file} alt=""  onClick={() => setPop({img_url : item.license_file})}/>

                                    <img src={item.business_file} alt=""  onClick={() => setPop({img_url : item.business_file})}/>
                                </div>
                            </div>

                            <div className="fragment-wrap">
                                {item.is_passed === "대기" ?
                                    <div className="fragment box-btns">
                                        <div>
                                            <button className="m-btn type02" onClick={()=>changeStatus("승인")}>승인</button>
                                        </div>
                                        <div>
                                            <button className="m-btn type02 bg-gray" onClick={()=>changeStatus("반려")}>반려</button>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {item.is_passed === "반려" ?
                                    <div className="fragment box-btns">
                                        <div>
                                            <button className="m-btn type02" onClick={()=>changeStatus("승인")}>승인</button>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {item.is_passed === "중지" ?
                                    <div className="fragment box-btns">
                                        <div>
                                            <button className="m-btn type02" onClick={()=>changeStatus("승인")}>승인</button>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {item.is_passed === "승인" ?
                                    <div className="fragment box-btns">
                                        <div>
                                            <button className="m-btn type02 bg-red" onClick={()=>changeStatus("중지")}>중지</button>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    : ""
            }

        </Fragment>
    );
};

const mapState = (state) => {
    return {
        user: state.common.user,
        token: state.common.token
    }
};

const mapDispatch = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        },
        setPop: (data) => {
            dispatch(setPop(data))
        }
    }
};

export default connect(mapState, mapDispatch)(Doctor);
