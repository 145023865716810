import React, {Fragment, useEffect, useState} from 'react';
import axios from "axios";
import {setUser} from "../actions/commonActions";
import {connect} from "react-redux";
import Time from "../components/Time";
import Doctor from "../components/Doctor";
import Doctors from "../components/Doctors";
import Pharmacists from "../components/Pharmacists";

const Main = ({user}) => {

	return (
		<div className="area-main area-admin">
			<div className="container">
				<div className="container-inner">
					<Doctors />

					<div className="mt-60"></div>

					<Pharmacists />
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {

	}
}
const mapStateToProps = (state) => {
	return {
		user: state.common.user
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);