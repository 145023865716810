import React, {Fragment, useEffect} from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout} from "../actions/commonActions";

const Header = ({}) => {
    // ...rest는 props 기타등등 이라는 뜻
    // location은 라우터의 현재 위치
    useEffect(() => {

    }, []);

    return (
        <footer className="footer">
            <div className="wrap">
                <div className="infos">
                    <div className="info point">DR.LINK</div>
                    <div className="info">© 2022 DR.LINK, Inc.</div>
                    <div className="info">경기 안양시 만안구 안양로 82, 201호</div>
                </div>
            </div>

        </footer>
    );
};

const mapState = (state) => {
    return {
        user: state.common.user,
        token: state.common.token
    }
};

const mapDispatch = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        }
    }
};

export default connect(mapState, mapDispatch)(Header);
